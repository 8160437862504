body {
  font-family: "SequelSansHeadline", -apple-system, "BlinkMacSystemFont", Helvetica, Arial;
  font-size: 15px;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgba(123, 125, 133, var(--tw-text-opacity));
}
ol, ul
{
  list-style: none;
  margin: 0;
  padding: 0;
}
ul
{
  list-style-type: disc;
}


ol,ul{
  list-style-position: inside;
}
ol li
{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
ol li p, ul li p
{
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline;
}
ol
{
  list-style-type: decimal;
}
p strong
{
  --tw-text-opacity: 1;
  color: rgba(93, 94, 99, var(--tw-text-opacity));
}

h1, h2, h3, h4, h5, h6
{
  margin-bottom: 1rem;
  display: block;
  font-family: "SharpGrotesk", -apple-system, "BlinkMacSystemFont", Helvetica, Arial;
  line-height: 1.25;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.container {
  /* width: 100%; */
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}
.updated-privacy-policy .intro-section > div h2
{
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "SequelSans", -apple-system, "BlinkMacSystemFont", Helvetica, Arial;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0em;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}


@media (min-width: 768px) {
  body {
    font-size: 16px;
  }

  p {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
 ul li, ol li
  {
      margin-top: 1rem;
      margin-bottom: 1rem;
  }

  .container {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .updated-privacy-policy {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.5rem;
    letter-spacing: -0.05em;
  }

  h1 {
    font-size: 64px;
  }

}

@media (min-width: 768px) {
  .container {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-left: 0px;
    padding-right: 0px;
  }
}


@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}


@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}